import React from 'react';
import Blink from 'react-blink-text';

const TextBig = ({
    children,
    fontWeight = 'normal',
    color = "black",
    onClick,
    blink = false,
    fontSize = "25px",
    wordBreak = "break-word",
}) => {

    const styles = {
        text: {
            fontFamily: "verdana",
            fontSize: fontSize,
            fontWeight: fontWeight,
            color: color,
            wordBreak: wordBreak,
        }
    };

    if (!blink) {
        return (
            <div style={styles.text} onClick={onClick}>
                {children}
            </div>
        )
    }
    else {
        return (
            <div style={styles.text} onClick={onClick}>
                <Blink color={color} text={children} fontSize='25px'>
                </Blink>
            </div>
        );
    }
};

export default TextBig;
